import React, {useEffect, useState} from "react"
import PropTypes from 'prop-types'
import {useDispatch, useSelector} from "react-redux"

import "./documents/documents.scss"
import DifferentInvoiceAdressInput from "./documents/differentInvoiceAdressInput"
import {
    submitDocuments,
    userToDocuments,
} from "../../redux/registration/registrationAction"
import Upload from "./documents/upload"
import {isDefined} from "../../utils/data"
import HelpUr from "../../components/help/help"
import AlertCategory from "./alertCategory/alertCategory";

const Documents = ({prevTab, nextTab}) => {
    const dispatch      = useDispatch()
    const {documents}   = useSelector(({registrationReducer}) => registrationReducer)
    const {category}    = useSelector(({registrationReducer}) => registrationReducer)
    const {information} = useSelector(({registrationReducer}) => registrationReducer)
    const {user}        = useSelector(({apiReducer}) => apiReducer)
    
    /*const ctgAndUnderDefined    = isDefined(information.registered_profession_category) && isDefined(information.registered_under)
    const ctgAndUnderCondition  = ctgAndUnderDefined ? information.registered_profession_category.value === "Nurse" && (information.registered_under.value === "0" || information.registered_under.value === true) : false*/

    const displayUpload = isDefined(category) ? category.idcategories === "8" || category.idcategories === "9" || category.idcategories === "16" || category.idcategories === "17" || category.idcategories === "18" || category.idcategories === "21" : false

    const [uploadIsVisible, setUploadIsVisible] = useState(displayUpload)

  

    useEffect(() => {
        if(user !== null)
            dispatch(userToDocuments(user))
    }, [])

    useEffect(() => {
        setUploadIsVisible(displayUpload)
    }, [information.registered_profession_category, information.registered_under])

    const onSubmit = (e = null) => {
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            e.preventDefault()
            e.stopPropagation()
        }

        dispatch(submitDocuments(user, documents, nextTab))
    }

    return (
        <div className={"content documents"}>
        <AlertCategory/>
            <form onSubmit={onSubmit} className={"needs-validation"} noValidate>
                <DifferentInvoiceAdressInput/>

                {uploadIsVisible === true ?
                    <div className={"form-group"}>
                        <Upload/>
                    </div>
                : ""}
                <div className={"content-footer"}>
                    <button className={"sr-btn-next btn btn-secondary"} onClick={prevTab}>Previous</button>
                    <small  className={"help"}><HelpUr/></small>
                    <button type={"submit"} className={"sr-btn-next btn btn-info"}>Next</button>
                </div>
            </form>
        </div>
    )
}

Documents.propTypes = {
    prevTab: PropTypes.func.isRequired,
    nextTab: PropTypes.func.isRequired,
}

export default Documents
