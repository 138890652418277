import React, {useEffect, useState} from "react"
import PropTypes from "prop-types"
import {useDispatch, useSelector} from "react-redux"

import RoundTicket from "../../components/roundTicket/roundTicket"
import {selectOptions} from "../../redux/registration/registrationAction"
import {validTab} from "../../redux/tabs/tabsAction"
import AlertCategory from "./alertCategory/alertCategory"
import {isDefined} from "../../utils/data"
import HelpUr from "../../components/help/help"

const Options = ({prevTab, nextTab}) => {
    const dispatch          = useDispatch()
    const { optionsAPI, user }    = useSelector(({apiReducer}) => apiReducer)
    const {category}    = useSelector(({registrationReducer}) => registrationReducer)
    const { ticket }       = useSelector(({registrationReducer}) => registrationReducer)
    const { options }       = useSelector(({registrationReducer}) => registrationReducer)
    const [items, setItems] = useState([])

    useEffect(() => {
        setItems(optionsAPI)
    }, [])

    const [optionsSelected, setOptionsSelected]     = useState(options)
    const [itemsSelected, setItemsSelected]         = useState(options.map(option => option.idoptions))
    const [optionsLimited, setOptionsLimited]       = useState(optionsSelected.filter(option => option.option_parent === "odt"))

    useEffect(() => {
        setOptionsLimited(optionsSelected.filter(option => option.option_parent === "odt"))
    }, [optionsSelected])
    
    const select = option => {
        const index = itemsSelected.indexOf(option.idoptions)
        const optionsLimited = optionsSelected.filter(option => option.option_parent === "odt")
        if(index !== -1) {
            const array = [...itemsSelected]
            const prevOptionsSelected = [...optionsSelected]
            array.splice(index, 1)
            setItemsSelected(array)
            prevOptionsSelected.splice(index, 1)
            setOptionsSelected(prevOptionsSelected)
            dispatch(selectOptions(option, false))
        }
        else {
            if(option.option_parent !== "odt" || (optionsLimited.length < 2 && option.option_parent === "odt" )) {
                dispatch(selectOptions(option))
                setItemsSelected(prevItems => prevItems.concat(option.idoptions))
                setOptionsSelected(prevItems => prevItems.concat(option))
            }
        }
    }

    const onClickNext = (e) => {
        e.preventDefault()
        dispatch(validTab("optionsTab"))
        nextTab()
    }

    const readOnly = option => isDefined(user) ? user.registered_option.find(obj => obj.idoptions === option.idoptions) !== undefined : false

    return (
        <div className={"content options"}>
                    <AlertCategory/>

        <p className={"title"}>Donation</p>
<p>Introduction text for special donation</p>
            <form>
                <div className={"row row-options"}>
                    {items.map((item, i) =>
                        parseInt(item.options_price) > 0 && item.included !== 1 && parseInt(ticket.idpackages) !== 2 ?
                            <RoundTicket key={i}
                                     name={item.options_name}
                                     price={item.options_price}
                                     isSelected={itemsSelected.find(obj => obj === item.idoptions) !== undefined}
                                     onClick={() => select(item)}
                                     isOption={true}
                                     readOnly={item.included === 1 || readOnly(item)}
                                     isLimited={optionsLimited.length >= 2 && item.option_parent === "odt"}
                            />
                        : ""
                     )}
                </div>
            </form>

            <div className={"content-footer"}>
                <button className={"sr-btn-next btn btn-secondary"} onClick={prevTab}>Previous</button>
                    <small  className={"help"}><HelpUr/></small>
                <button className={"sr-btn-next btn btn-info"} disabled={optionsLimited.length === 0 && parseInt(ticket.idpackages) === 3} onClick={onClickNext}>Next</button>
            </div>
        </div>
    )
}

Options.propTypes = {
    prevTab: PropTypes.func.isRequired,
    nextTab: PropTypes.func.isRequired,
}

export default Options
